import {
  getCurrency,
  getFormattedMoney,
  WIX_EVENTS_TICKET_DEFINITION_FQDN,
  PlaceWithTicketInfo,
  getEventTicketLimitPerOrder,
} from '@wix/wix-events-commons-statics'
import {createSelector} from 'reselect'
import {PlanReservations} from '@wix/ambassador-seating-v1-seating-plan/types'
import {SelectedTickets, State, TicketsToPlaces} from '../types'
import {getSelectedTickets, getSelectedTicketsQuantity} from './selected-tickets'

export const getTicketDefinitionByExternalId = (tickets: wix.events.ticketing.TicketDefinition[], externalId: string) =>
  tickets.find(ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''))

export const getPlaces = (state: State) => state.seating.places

export const getSelectedFormattedPrice = (state: State) => {
  const selectedPrice = state.seating.selectedPrice
  if (selectedPrice) {
    return getFormattedMoney({amount: selectedPrice, currency: getCurrency(state.event)})
  }
}

export const getSelectedZone = (state: State) => state.seating.selectedZone

export const getPlanReservations = (state: State) => state.seating.planReservations.reservations

export const getPlacesInBasket = createSelector(getPlaces, places => places.filter(place => place.inBasket))

export const getFilteredPlaces = createSelector(
  getPlaces,
  getSelectedFormattedPrice,
  getSelectedZone,
  getPlanReservations,
  (places, price, zone, reservations) => {
    places = places.filter(place => (place.capacity - reservations[place.id]?.occupied ?? 0) && place.ticketId)

    if (price) {
      places = places.filter(place => place.ticketPrice === price)
    }

    if (zone) {
      places = places.filter(place => `${place.elementType}_${place.elementLabel}` === zone)
    }

    return places
  },
)

export const getSelectedTicketsWithPlaceInfo = createSelector(getPlaces, places =>
  places
    .filter(place => place.inBasket)
    .reduce((acc, place) => [...acc, ...new Array(place.quantity).fill(place)], [] as PlaceWithTicketInfo[]),
)

export const getSelectedTicketsFromSeats = (state: State): SelectedTickets => {
  const placesInBasket = getSelectedTicketsWithPlaceInfo(state)

  return placesInBasket.reduce((acc, item) => {
    const quantity = acc[item.ticketId]?.quantity || 0
    return {
      ...acc,
      [item.ticketId]: {
        quantity: quantity + 1,
      },
    }
  }, {})
}

export const getTicketsToPlaces = (state: State): TicketsToPlaces => {
  const placesInBasket = getSelectedTicketsWithPlaceInfo(state)
  const selectedTickets = getSelectedTickets(state)

  return Object.keys(selectedTickets).reduce((acc, ticket) => {
    const placeIds = placesInBasket.filter(place => place.ticketId === ticket).map(({id}) => id)
    return {
      ...acc,
      [ticket]: placeIds,
    }
  }, {})
}

export const getSelectedPlace = createSelector(getPlaces, places => places.find(place => place.selected))

export const getPlaceQuantity = createSelector(
  (places: PlaceWithTicketInfo[], id: string): number => getPlaceInfo(places, id)?.quantity ?? 0,
  places => places,
)

export const getPlaceInfo = (places: PlaceWithTicketInfo[], id: string): PlaceWithTicketInfo | undefined =>
  places.find(place => place.id === id)

export const isTicketLimitReached = (state: State) =>
  getEventTicketLimitPerOrder(state.event) === getSelectedTicketsQuantity(state)

export const getLegendItems = (state: State) =>
  state.seating.plan.categories
    .filter(category => Boolean(category.places.length))
    .map(({externalId, config: {color}}) => {
      const ticketDefinition = getTicketDefinitionByExternalId(state.tickets, externalId)

      if (!ticketDefinition) {
        return null
      }

      return {
        color,
        price: getFormattedMoney(ticketDefinition.price),
      }
    })
    .filter(Boolean)

export const calculatePlacesStock = (places: PlaceWithTicketInfo[], planReservations: PlanReservations) =>
  places.reduce((acc, place) => {
    if (place.ticketId) {
      acc[place.id] = place.capacity - place.quantity - planReservations.reservations[place.id].occupied
    } else {
      acc[place.id] = 0
    }

    return acc
  }, {} as Record<string, number>)

export const isShowAccessibilityMode = (state: State) => state.seating.showAccessibilityMode
