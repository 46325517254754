import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {addCircularTabFocus, focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SlideInModal} from '../../slide-in-modal'
import {Header} from './header'
import {Filters} from './filters'
import {classes} from './accessibility-mode.st.css'
import {Footer} from './footer'
import {TicketsCountInfo} from './tickets-count-info'
import {TicketsList} from './tickets-list'
import {AccessibilityModeProps} from '.'

export const AccessibilityMode = ({
  showAccessibilityMode,
  setShowAccessibilityMode,
  onFooterButtonClick,
  places,
  setForcedPopover,
}: AccessibilityModeProps) => {
  const placesCount = places.length
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()

  React.useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.BACK), canFocus: true})
    addCircularTabFocus(hookToAttributeSelector(DH.CLOSE_BUTTON), hookToAttributeSelector(DH.ACCESSIBILITY_MODE_END))
  }, [showAccessibilityMode])

  if (isMobile) {
    return (
      <div className={classes.mobileContainer}>
        <Filters />
        {placesCount ? <TicketsCountInfo placesCount={placesCount} /> : null}
        <TicketsList places={places} />
        <Footer onButtonClick={onFooterButtonClick} />
      </div>
    )
  }

  return (
    <SlideInModal
      className={classes.container}
      show={showAccessibilityMode}
      backButtonText={t('seatings_closeTicketList')}
      onBackClick={() => setShowAccessibilityMode(false)}
    >
      <Header />
      <Filters />
      {placesCount ? <TicketsCountInfo placesCount={placesCount} /> : null}
      <TicketsList places={places} setForcedPopover={setForcedPopover} />
      <Footer />
    </SlideInModal>
  )
}
