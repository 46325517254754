import * as React from 'react'
import {DropdownOptionProps} from 'wix-ui-tpa/Dropdown'
import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {SeatingPlan, Type, Element} from '@wix/ambassador-seating-v1-seating-plan/types'
import {useTranslation, useEnvironment, TFunction} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import s from './filters.scss'
import {StyledFloatingDropdown} from './styled-dropdown'
import {VerticalDivider} from './vertical-divider'
import {FilterContainer} from './filter-container'
import {FiltersProps} from '.'

const DEFAULT_OPTION_ID = 'all'

export const Filters = ({
  plan,
  tickets,
  selectPrice,
  selectLocation,
  selectedZone: selectedZoneId,
  selectedPrice: selectedPriceId,
  showAccessibilityMode,
}: FiltersProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const priceOptions = getPriceOptions(tickets, t)

  const selectedPriceOptionId =
    (selectedPriceId && priceOptions.find(price => price.id === selectedPriceId)?.id) ?? DEFAULT_OPTION_ID

  const selectedPriceValue = priceOptions.find(({id}) => id === selectedPriceOptionId).value

  const zoneOptions = getZoneOptions(plan, t)

  const selectedZoneOptionId =
    (selectedZoneId && zoneOptions.find(element => element.id === selectedZoneId)?.id) ?? DEFAULT_OPTION_ID

  const selectedZoneValue = zoneOptions.find(({id}) => id === selectedZoneOptionId).value

  React.useEffect(() => {
    selectPrice(null)
    selectLocation(null)
  }, [showAccessibilityMode, selectPrice, selectLocation])

  return (
    <div className={classNames(s.container, {[s.mobileContainer]: isMobile})}>
      <FilterContainer>
        <StyledFloatingDropdown
          dataHook={DH.SEATING_PRICE_FILTER}
          label={t('seatings_filters_priceLabel')}
          value={selectedPriceOptionId}
          ariaLabel={t('seatings_filters_priceAriaLabel', {value: selectedPriceValue})}
          options={priceOptions}
          onChange={selectedOption => selectPrice(selectedOption.id === DEFAULT_OPTION_ID ? null : selectedOption.id)}
        />
      </FilterContainer>
      {isMobile ? null : <VerticalDivider />}
      <FilterContainer>
        <StyledFloatingDropdown
          dataHook={DH.SEATING_ZONE_FILTER}
          value={selectedZoneOptionId}
          label={t('seatings_filters_zoneLabel')}
          ariaLabel={t('seatings_filters_zoneAriaLabel', {value: selectedZoneValue})}
          options={zoneOptions}
          onChange={selectedOption =>
            selectLocation(selectedOption.id === DEFAULT_OPTION_ID ? null : selectedOption.id)
          }
        />
      </FilterContainer>
    </div>
  )
}

const getDefaultOption = (t: TFunction) => ({id: 'all', value: t('seatings_filters_all'), isSelectable: true})

const getPriceOptions = (tickets: wix.events.ticketing.TicketDefinition[], t: TFunction): DropdownOptionProps[] => [
  getDefaultOption(t),
  ...tickets
    .map(ticket => ({
      id: ticket.price.value,
      value: getFormattedMoney(ticket.price),
      isSelectable: true,
    }))
    .filter(
      ({value}, index, allPrices) => allPrices.findIndex(({value: searchValue}) => value === searchValue) === index,
    )
    .sort(ascByKey('value')),
]

const getZoneOptions = (plan: SeatingPlan, t: TFunction): DropdownOptionProps[] => {
  const zoneTypes = [
    {types: [Type.ROW], zoneTypeTranslation: 'seatings_filters_rows', itemTranslation: 'seatings_filters_row'},
    {
      types: [Type.TABLE, Type.ROUND_TABLE],
      zoneTypeTranslation: 'seatings_filters_tables',
      itemTranslation: 'seatings_filters_table',
    },
    {types: [Type.AREA], zoneTypeTranslation: 'seatings_filters_areas', itemTranslation: 'seatings_filters_area'},
  ]

  const allElements = plan.sectors.flatMap(sector => sector.elements)

  return [
    getDefaultOption(t),
    ...zoneTypes.flatMap(({types, zoneTypeTranslation, itemTranslation}) => {
      const typeElements: Element[] = allElements
        .filter(element => types.includes(element.type))
        .reduce((acc, curr) => {
          if (!acc.find(item => item.title === curr.title)) {
            acc.push(curr)
          }

          return acc
        }, [])
      const typeElementsCount = typeElements.length

      if (!typeElementsCount) {
        return []
      }

      return [
        {
          id: types.join(','),
          value: t(zoneTypeTranslation, {count: typeElementsCount}),
          isSectionTitle: true,
          isSelectable: false,
        },
        ...typeElements
          .map(element => ({
            id: `${element.type}_${element.title}`,
            value: t(itemTranslation, {title: element.title}),
            isSelectable: true,
            isSectionTitle: false,
          }))
          .sort(ascByKey('value')),
      ]
    }),
  ]
}

const ascByKey = (key: string) => ({[key]: a}: any, {[key]: b}: any) => {
  if (!isNaN(Number(a)) && !isNaN(Number(b))) {
    return a - b
  } else {
    return a.localeCompare(b)
  }
}
