import {getPreliminaryInvoice, getTaxConfig} from '@wix/wix-events-commons-statics'
import {FeeType} from '@wix/events-types'
import {State, OrderedTicket} from '../types'
import {getBestPurchasedPlan, getSelectedPaidPlan} from './paid-plans'
import {getTicketById} from './tickets'
import {getInvoice as getInvoiceFromState} from './invoice'
import {getSortedSelectedTickets, getSelectedTickets} from './selected-tickets'
import {getTicketsToPlaces} from './seating'

export const getInvoice = (state: State): wix.events.ticketing.Invoice => {
  const order = state.placedOrder.order

  if (order?.invoice) {
    return mapInvoice(order.invoice)
  }

  if (state.invoice?.invoice) {
    return getInvoiceFromState(state)
  }

  const paidPlan = getSelectedPaidPlan(state) || getBestPurchasedPlan(state)
  const selectedTickets = getSelectedTickets(state)

  return getPreliminaryInvoice(state.tickets, selectedTickets, getTaxConfig(state.event), paidPlan)
}

const mapInvoice = invoice => ({
  ...invoice,
  fees: (invoice.fees || []).map(fee => ({
    ...fee,
    type: getFeeType(fee.type),
  })),
})

const getFeeType = (type: FeeType) => {
  if (type === FeeType.FEE_INCLUDED) {
    return FeeType.FEE_INCLUDED
  }

  if (type === FeeType.FEE_ADDED) {
    return FeeType.FEE_ADDED
  }

  if (type) {
    return FeeType.FEE_ADDED_AT_CHECKOUT
  }
}

export const isOrderCompleted = (order: wix.events.ticketing.Order) => Boolean(order?.snapshotId)

export const onlyFreeTicketsSelected = (order: wix.events.ticketing.Order) =>
  Number(order.invoice.grandTotal.amount) === 0

export const getOrderedTickets = (state: State, tickets: wix.events.ticketing.TicketDefinition[]): OrderedTicket[] => {
  const selectedTickets = getSortedSelectedTickets(state)
  const ticketsToPlaces = getTicketsToPlaces(state)

  return Object.keys(selectedTickets)
    .map(ticketDefId => {
      const orderedTicket = getTicketById(tickets, ticketDefId) as OrderedTicket
      orderedTicket.orderedCount = selectedTickets[ticketDefId].quantity
      orderedTicket.seatIds = ticketsToPlaces[ticketDefId] ?? []
      return orderedTicket
    })
    .filter(ticket => ticket.orderedCount)
}

export const getTickets = (state: State) => state.placedOrder.order.tickets ?? []

export const getTicketsPdf = (state: State) => state.placedOrder.order.ticketsPdf

export const getTicketQuantity = (state: State) => state.placedOrder.order.ticketsQuantity

export const getGrandTotal = (state: State): number => Number(state.placedOrder.order.invoice.grandTotal.amount)

export const getPaymentMethod = (state: State) => state.placedOrder.order.method

export const getOrderNumber = (state: State) => state.placedOrder.order.orderNumber

export const getNonFreeTicketsCount = (state: State) =>
  state.placedOrder.order?.invoice.items.reduce(
    (sum, item) => sum + ((Number(item.total.amount) && item.quantity) || 0),
    0,
  )
